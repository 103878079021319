// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-carga-archivos-js": () => import("./../../../src/templates/carga-archivos.js" /* webpackChunkName: "component---src-templates-carga-archivos-js" */),
  "component---src-templates-carga-archivos-pensionados-js": () => import("./../../../src/templates/carga-archivos-pensionados.js" /* webpackChunkName: "component---src-templates-carga-archivos-pensionados-js" */),
  "component---src-templates-carga-ingresos-js": () => import("./../../../src/templates/carga-ingresos.js" /* webpackChunkName: "component---src-templates-carga-ingresos-js" */),
  "component---src-templates-comprobante-ingresos-js": () => import("./../../../src/templates/comprobante-ingresos.js" /* webpackChunkName: "component---src-templates-comprobante-ingresos-js" */),
  "component---src-templates-correccion-datos-js": () => import("./../../../src/templates/correccion-datos.js" /* webpackChunkName: "component---src-templates-correccion-datos-js" */),
  "component---src-templates-datos-adicionales-js": () => import("./../../../src/templates/datos-adicionales.js" /* webpackChunkName: "component---src-templates-datos-adicionales-js" */),
  "component---src-templates-descargar-aplicacion-js": () => import("./../../../src/templates/descargar-aplicacion.js" /* webpackChunkName: "component---src-templates-descargar-aplicacion-js" */),
  "component---src-templates-encuesta-js": () => import("./../../../src/templates/encuesta.js" /* webpackChunkName: "component---src-templates-encuesta-js" */),
  "component---src-templates-felicidades-js": () => import("./../../../src/templates/felicidades.js" /* webpackChunkName: "component---src-templates-felicidades-js" */),
  "component---src-templates-firma-digital-js": () => import("./../../../src/templates/firma-digital.js" /* webpackChunkName: "component---src-templates-firma-digital-js" */),
  "component---src-templates-lo-sentimos-js": () => import("./../../../src/templates/lo-sentimos.js" /* webpackChunkName: "component---src-templates-lo-sentimos-js" */),
  "component---src-templates-muchas-gracias-js": () => import("./../../../src/templates/muchas-gracias.js" /* webpackChunkName: "component---src-templates-muchas-gracias-js" */),
  "component---src-templates-numero-rastreo-js": () => import("./../../../src/templates/numero-rastreo.js" /* webpackChunkName: "component---src-templates-numero-rastreo-js" */),
  "component---src-templates-referencias-js": () => import("./../../../src/templates/referencias.js" /* webpackChunkName: "component---src-templates-referencias-js" */),
  "component---src-templates-seguro-vida-js": () => import("./../../../src/templates/seguro-vida.js" /* webpackChunkName: "component---src-templates-seguro-vida-js" */),
  "component---src-templates-simulador-aef-altoperfil-js": () => import("./../../../src/templates/simulador-aef-altoperfil.js" /* webpackChunkName: "component---src-templates-simulador-aef-altoperfil-js" */),
  "component---src-templates-tu-historial-broker-js": () => import("./../../../src/templates/tu-historial-broker.js" /* webpackChunkName: "component---src-templates-tu-historial-broker-js" */),
  "component---src-templates-tu-historial-nip-js": () => import("./../../../src/templates/tu-historial-nip.js" /* webpackChunkName: "component---src-templates-tu-historial-nip-js" */),
  "component---src-templates-tu-oferta-js": () => import("./../../../src/templates/tu-oferta.js" /* webpackChunkName: "component---src-templates-tu-oferta-js" */),
  "component---src-templates-tu-proceso-js": () => import("./../../../src/templates/tu-proceso.js" /* webpackChunkName: "component---src-templates-tu-proceso-js" */),
  "component---src-templates-tus-datos-js": () => import("./../../../src/templates/tus-datos.js" /* webpackChunkName: "component---src-templates-tus-datos-js" */),
  "component---src-templates-validacion-js": () => import("./../../../src/templates/validacion.js" /* webpackChunkName: "component---src-templates-validacion-js" */),
  "component---src-templates-verificacion-bancaria-js": () => import("./../../../src/templates/verificacion-bancaria.js" /* webpackChunkName: "component---src-templates-verificacion-bancaria-js" */),
  "component---src-templates-verificacion-comprobante-js": () => import("./../../../src/templates/verificacion-comprobante.js" /* webpackChunkName: "component---src-templates-verificacion-comprobante-js" */),
  "component---src-templates-verificacion-credencial-js": () => import("./../../../src/templates/verificacion-credencial.js" /* webpackChunkName: "component---src-templates-verificacion-credencial-js" */),
  "component---src-templates-verificacion-domicilio-js": () => import("./../../../src/templates/verificacion-domicilio.js" /* webpackChunkName: "component---src-templates-verificacion-domicilio-js" */),
  "component---src-templates-verificacion-telefonica-js": () => import("./../../../src/templates/verificacion-telefonica.js" /* webpackChunkName: "component---src-templates-verificacion-telefonica-js" */),
  "component---src-templates-video-llamada-js": () => import("./../../../src/templates/video-llamada.js" /* webpackChunkName: "component---src-templates-video-llamada-js" */),
  "component---src-templates-video-verificacion-js": () => import("./../../../src/templates/video-verificacion.js" /* webpackChunkName: "component---src-templates-video-verificacion-js" */)
}

